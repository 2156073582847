
/*
 *  This file auth-service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, first, tap } from "rxjs/operators";
import { ServerErrorHandler } from "./error-handler/server-error-handler.service";
import { ConfigurationService } from "./configuration.service";
import { EnterpriseWallService } from "src/app/modules/enterprise-wall/enterprise-wall.service";
import { RequestMenuModel } from "../models/request-menu.model";
import { menuNavigationModel } from "../constants/routing-constant";
import { SharedService } from "./shared.service";
import { CommonDropdownService } from "./dropdowns/common-dropdown.service";
import { LocationDropdownsService } from "./dropdowns/location-dropdowns.service";
import { OsDropdownsService } from "./dropdowns/os-dropdowns.service";
import { UserRightsService } from "./user-rights.service";
import { HttpService } from "./http-service";
import { LogonUserDetailModel, TLoginTokenResponse } from "../models/common.models";
import * as moment from "moment";
import { IHroneGenericResponse } from "src/app/modules/enterprise-wall/social-enterpise-wall/social-wall.model";
import { SingleSignonService } from "src/app/authentication/single-signon.service";
import { SnackbarAlertService } from "./snackbar-alert.service";


@Injectable()
export class AuthService {
  onloggedInEvent = new EventEmitter();
  onCloseDialogEvent = new EventEmitter();
  otpResendEvent = new Subject()
  onLogout$ = new Subject();

  base_auth_url;
  refresh_token;
  domainCode;
  base_url;
  employeeInfoAfterLogin = null;
  superUserInfoAfterLogin = null;
  newAddedMenu = [];
  pageName = '';
  currentOption: { Id: number, detail: string, type: string, isUsed: boolean };
  onNextClicked: boolean;

  constructor(
    private _httpClient: HttpClient,
    public _router: Router,
    private _serverError: ServerErrorHandler,
    public _config: ConfigurationService,
    public _wall: EnterpriseWallService,
    private _shared: SharedService,
    private http: HttpService,
    public _common: CommonDropdownService,
    public _location: LocationDropdownsService,
    public _osdd: OsDropdownsService,
    public _userRights: UserRightsService,
    public _singleSignon: SingleSignonService,
    private _snackBar: SnackbarAlertService
  ) {
    this.domainCode = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : _config.domainCode;
    this.base_url = _config["base_url"];
    this.base_auth_url = _config["base_auth_url"];
    this.newAddedMenu = JSON.parse(localStorage.getItem('newAddedMneu'));
  }

  getHeaders() {
    const authlogin = JSON.parse(localStorage.getItem("authlogin"));
    const accessToken = authlogin ? authlogin.access_token : "";
    return new HttpHeaders({
      "Content-Type": "application/json",
      domainCode: this.domainCode,
      Authorization: "Bearer " + accessToken,
      AccessMode: 'W'
    });
  }

  getLoginHeaders() {
    const authlogin = JSON.parse(localStorage.getItem("authlogin"));
    const accessToken = authlogin ? authlogin.access_token : "";
    return new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      domainCode: this.domainCode,
      Authorization: "Bearer " + accessToken,
      AccessMode: 'W'
    });
  }

  public Get(url): Observable<any> {
    return this._httpClient
      .get(this.base_url + url, { headers: this.getHeaders() })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public Post(url, param): Observable<any> {
    return this._httpClient
      .post(this.base_url + url, param, { headers: this.getHeaders() })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public PostUnAuthorize(url, param): Observable<any> {
    const headers = this.getHeaders();
    headers.delete("Authorization");
    return this._httpClient
      .post(this.base_auth_url + url, param, { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')));
  }

  public LoginUnAuthorized(url, param): Observable<any> {
    const headers = this.getLoginHeaders();
    headers.delete("Authorization");
    return this._httpClient
      .post(this.base_auth_url + url, param, { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')));
  }

  encodedPost(url, param) {
    const headers = this.getLoginHeaders();
    return this._httpClient.post(this.base_auth_url + url, param, { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')));
  }

  setLoginAuth(data) {
    localStorage.setItem("authlogin", JSON.stringify(data));
    this._shared.getTokenInfo();
  }



  isAuthenticated() {
    if (localStorage.getItem("authlogin")) {
      return true;
    }
    this.http.updateSignalRAuthTokenSubject.next({ token: null, isLogout: true });
    return false;
  }

  isAuthenticatedOrSSO(): boolean {
    const token: TLoginTokenResponse | null = JSON.parse(localStorage.getItem("authlogin"));
    return token && (token?.refresh_token || "").toLowerCase() !== 'na' && !this.onNextClicked;
  }


  isAccessTokenExpired(): boolean {
    if (JSON.parse(localStorage.getItem("isRefreshingToken"))) return false;
    const gmtNow = new Date().toUTCString();
    const token: TLoginTokenResponse | null = JSON.parse(localStorage.getItem("authlogin"));
    if ((token?.refresh_token || "").toLowerCase() === 'na') return false;
    const howManyUnitsAheadFromExpiry = moment(token['.expires']).diff(moment(gmtNow), 'seconds');
    return token === null || howManyUnitsAheadFromExpiry <= token.expires_in / 2;
  }


  logout() {
    const isSuperUser = JSON.parse(localStorage.getItem('authlogin'))?.isSuperUser;
    try {
      if (localStorage.getItem('authlogin')) {
        const authlogin = JSON.parse(localStorage.getItem("authlogin"));
        const accessToken = authlogin ? authlogin.access_token : "";
        if (this._shared.supportUserInfo && this._shared.supportUserInfo.supportUserLoginId && this._shared.supportUserInfo.mappedUserId) {
          this.logOutFromSupportUser(accessToken);
        } else {
          this.logOutFromWeb(accessToken);
        }
      }
      this.clearLocalStorage();
      if (isSuperUser) this._router.navigate(['/product-management/login']);
      if (!isSuperUser) this._router.navigate(["login"]);
    } catch (err) { }


    try {
      this._wall.resetAllData();
      this._common.reset();
      this._location.reset();
      this._osdd.reset();
    } catch (err) { }



    this._shared.reset();
    this.onLogout$.next(Math.random());
  }

  clearLocalStorage() {
    localStorage.removeItem('authlogin');
    localStorage.removeItem('newAddedMneu');
    localStorage.removeItem('rawMenusWithActions');
    localStorage.removeItem('socketId');
    localStorage.removeItem('rawMenus');
    localStorage.removeItem('logOnUserDetails');
    localStorage.removeItem('whiteLabels');
    localStorage.removeItem('uid');
    localStorage.removeItem('GC_Accesstoken');
    localStorage.removeItem('requestSource');
    localStorage.removeItem('selectedHRDeskSearch');
    if (this.employeeInfoAfterLogin?.employeeStatusId == 7) {
      localStorage.removeItem('recent_enterprise_search');
    }
    localStorage.removeItem('samlSessionIndex');
  }

  logOutFromWeb(accessToken) {
    if (accessToken) {
      try {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          domainCode: this.domainCode,
          Authorization: "Bearer " + accessToken,
          AccessMode: 'W'
        });

        this._httpClient
          .get(this.base_url + "/api/LogOnUser/LogOff", {
            headers: headers
          })
          .subscribe(res => {
            localStorage.removeItem('launchDialog');
          });
      } catch (err) { }
    }
  }



  getLoginInfo(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.Get('/api/LogOnUser/LogOnUserDetail').subscribe(res => {
        if (res) {
          if (res.isLoggedOn < 2) this.updateLogOnStatus();
          this.employeeInfoAfterLogin = res;
          localStorage.setItem('logOnUserDetails', JSON.stringify(res));
          localStorage.setItem('isEmailBlocked', JSON.stringify(res?.isEmailBlocked));
          this._shared.logOnUserDetails = res;

          if (this._shared.logOnUserDetails && this._shared.logOnUserDetails.identifier) {
            this._shared.logOnUserIdentifiers = this._shared.logOnUserDetails.identifier;
          }
          this.getOnboardingStatus().subscribe({
            next: settingRes => {
              if (settingRes?.status === 'Y') {
                this.isProcessStart().subscribe({
                  next: onboardingRes => {
                    this._shared.onboardingAccess = true;
                    if (!onboardingRes.isProcessStart) {
                      this.getOtherInfo().then((res: boolean) => {
                        this._shared.userLoggedOut.next(res);
                        resolve(res);
                      }).catch(() => reject(false))
                      return
                    }
                    const pageNumber: number = onboardingRes?.selectedOnPageNumber ? onboardingRes?.selectedOnPageNumber : 1;
                    localStorage.setItem('isProcessStart', JSON.stringify(true))
                    localStorage.setItem('onboardingPageInfo', JSON.stringify({ segmentTypeId: onboardingRes?.selectedSegmentTypeId, pageNumber }))
                    if (onboardingRes?.selectedSegmentTypeId) this._router.navigate(['/app/onboarding-checklist', pageNumber, onboardingRes?.selectedSegmentTypeId]);
                    else this._router.navigate(['/onboarding-config', pageNumber])
                    this.getOtherInfo().then(() => {
                      resolve(false);
                    }).catch(() => reject(false));
                  },
                  error: () => {
                    this.getOtherInfo().then((res: boolean) => {
                      this._shared.userLoggedOut.next(res);
                      resolve(res);
                    }).catch(() => reject(false))
                  }
                })
              } else {
                this.getOtherInfo().then((res: boolean) => {
                  this._shared.userLoggedOut.next(res);
                  resolve(res);
                }).catch(() => reject(false));
              }
            },
            error: () => {
              this.getOtherInfo().then((res: boolean) => {
                this._shared.userLoggedOut.next(res);
                resolve(res);
              }).catch(() => reject(false))
            }
          })
        } else
          resolve(false);
      }, () => reject(false));
    });
  }

  getOnboardingStatus() {
    const url = '/api/workforce/setup/Setting/68/0,1';
    return this.http.Get(url);
  }

  isProcessStart() {
    const onboardingURL = '/api/core/onboard/CheckProcess';
    return this.http.Get(onboardingURL)
  }

  updateLogOnStatus() {
    const url = '/api/workforce/Employee/UpdateLogOnStatus';
    this.http.Put(url, {}).subscribe(res => res)
  }
  getOtherInfo(): any {
    return new Promise((resolve, reject) => {
      const MenuModelObject = {
        menuType: 'P',
        parentKey: 9999,
        menuId: 0,
        includeMenu: true
      };
      const MenuModelObject2 = {
        menuType: 'A',
        parentKey: 9999,
        menuId: 0,
        includeMenu: true
      };
      const menuAction = this.Get('/api/core/MenuAccess');
      const menuList = this.Post('/api/core/MenuAccess', MenuModelObject);
      const userLicenceInfo = this.Get('/api/UserLicense/info');
      const menuAccess = this.Post('/api/core/MenuAccess', MenuModelObject2).pipe(catchError(err => of([])));
      forkJoin([menuAction, menuList, menuAccess, userLicenceInfo])
        .pipe(
          first(),
          tap(data => {

            if (data[0].menuPermissionRights && data[0].menuPermissionRights.length) {
              data[0].menuPermissionRights = data[0].menuPermissionRights.map(menu => {
                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                return menu;
              });
              localStorage.setItem('rawMenusWithActions', JSON.stringify(data[0].menuPermissionRights));
              this._shared.rawMenusWithActions = data[0].menuPermissionRights;
              this._userRights.rawMenusWithActions = data[0].menuPermissionRights;
            }

            if (data[1].length) {
              data[1] = data[1].map(menu => {
                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                return menu;
              });
              const rawMenus = data[1];
              try { rawMenus.find(e => e.menuId === 64).sequenceNumber = 6; } catch (e) { }
              this._shared.bindNewBetaOnMenu(data[1]);
              const rawMenusNew = RequestMenuModel.concat(rawMenus);
              menuNavigationModel.forEach(item => {
                try {
                  rawMenusNew.find(e => e.navigationCode === item.code).navigationPath = item.path;
                } catch (e) { }
              });
              localStorage.setItem('rawMenus', JSON.stringify(rawMenusNew));
              this._shared.rawMenus = rawMenusNew;
              this._shared.refreshRecentSearchedProfileData();
            }

            if (data[2].length) {
              data[2] = data[2].map(menu => {
                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                return menu;
              });
              this.newAddedMenu = data[2];
              localStorage.setItem('newAddedMneu', JSON.stringify(data[2]));
            }
            
            if(data.length >= 4){
              this._shared.userLicenseInfo = data[3];
            }
            this._shared.listenForLogin$.next();
            resolve(true);
          })
        ).toPromise().then(() => { }).catch(() => {
          reject(false);
        });
    });
  }


  logOutFromSupportUser(accessToken) {
    if (accessToken) {
      try {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          domainCode: this.domainCode,
          Authorization: "Bearer " + accessToken,
          AccessMode: 'W'
        });
        const supportUser = encodeURIComponent(this._shared.supportUserInfo.supportUserLoginId);
        const mappedUser = encodeURIComponent(this._shared.supportUserInfo.mappedUserId);
        this._httpClient
          .post(this.base_url + `/api/support/logOff?supportUserLoginId=${supportUser}&domainCode=${this._shared.supportUserInfo.domainCode}&mappedUserId=${mappedUser}`, '', {
            headers: headers
          })
          .subscribe(() => {
            this._shared.supportUserInfo = null;
          });
      } catch (err) {
        console.log('web log out error', err);
      }
    }
  }

  getRefreshToken(): Observable<TLoginTokenResponse> {
    const authlogin = JSON.parse(localStorage.getItem("authlogin"));
    const url = "/oauth2/token";
    const logOnUserDetails = JSON.parse(localStorage.getItem('logOnUserDetails'));
    const username = localStorage.getItem('logOnUserName') ? localStorage.getItem('logOnUserName') : logOnUserDetails ? logOnUserDetails.officialEmail : '';
    const body = `refreshId=${authlogin ? authlogin.refresh_token : ''}&companyDomainCode=${logOnUserDetails ? logOnUserDetails.domainCode : ''}&grant_type=refresh_token&username=${username || ''}`;
    return this.LoginUnAuthorized(url, body);
  }
  callsAfterLogin(): void {
    this._httpClient.get("https://api.ipify.org?format=json").subscribe((json: { ip: string }) => {
      if (json && json.ip) {
        const url = '/api/LogOnUser/AuditTrail';
        const reqData = {
          "logOnSource": "W",
          "internetProtocolAddress": json.ip,
          "remarks": ""
        };
        const newBetaJSON = this._httpClient.get('/assets/json/new-beta.json');
        const dashboardTaxRegime = this.Get('/api/core/Dashboard/Taxregime/show').pipe(catchError(err => of(null)));
        const commonLoginEvent = this.Get('/api/engage/employee/CommonLoginEvent/' + this._shared.logOnUserDetails.employeeId).pipe(catchError(err => of([])));
        const sendIpAddress = this.Post(url, reqData).pipe(catchError(err => of(null)));
        const whiteLabels = this.Get('/api/core/WhiteLabeling').pipe(catchError(err => of([])));
        forkJoin([newBetaJSON, dashboardTaxRegime, commonLoginEvent, sendIpAddress, whiteLabels]).pipe(first(), tap(data => {
          console.log(data);
          this._shared.betaValue = data[0];
          if (data[1]) {
            if (data[1].financialYear) {
              this._shared.dashboardRegimeRes.financialYear = data[1].financialYear;
            }
            if (data[1].showTaxRegime) {
              this._shared.dashboardRegimeRes.showTaxRegime = true;
            }
            if (data[1].taxRegime) {
              this._shared.dashboardRegimeRes.taxRegime = data[1].taxRegime;
            }
            if (data[1].considerConfirmInvestment) {
              this._shared.dashboardRegimeRes.considerConfirmInvestment = true;
            }
          }
          if (data[2] && data[2].length) {
            this._shared.commonLoginPopupData = data[2];
            this._shared.pulseFeedbackArr = data[2].filter(e => e.serviceEventId === 2);
            this._shared.getPulseData(this._shared.pulseFeedbackArr[0]);
          }
          if (data[4] && data[4].length) {
            localStorage.setItem('whiteLabels', JSON.stringify(data[4]));
            data[4].forEach(ele => {
              const prop = (ele.originalDisplayName)?.toLowerCase();
              this._shared.whiteLabels[prop] = ele.displayName;
            });
          }
        })).subscribe();
      }
    });
  }

  logOutFromSingleSignOn(logOnTypeDetails: any, logOnUserDetails: LogonUserDetailModel, samlSessionIndex: string): void {
    if (logOnTypeDetails && logOnTypeDetails.isSingleSignOnEnable && logOnTypeDetails.isActiveUser && logOnTypeDetails.logOnSetting) {
      if (logOnTypeDetails.logOnSetting.logOnType == 5) {
        if (logOnTypeDetails.logOnSetting.subLogOnType == 3) {
          const header = new HttpHeaders({ 'Content-Type': 'application/json' });
          this._httpClient.delete(logOnTypeDetails.logOnSetting.logOffPath, { headers: header, withCredentials: true }).subscribe();
        } else {
          if (samlSessionIndex) {
            const headers = new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              AccessMode: 'W',
              domainCode: localStorage.getItem('domainCode'),
            });
            const body = new URLSearchParams({
              logOnUserId: logOnUserDetails.employeeId.toString(),
              domainCode: logOnUserDetails.domainCode,
              sessionIndex: samlSessionIndex
            }).toString();
            this._httpClient.post(this.http._config.base_auth_url + '/api/saml/request/logout', body, { headers }).subscribe((res: IHroneGenericResponse) => {
              if (res?.validationType == '0') {
                this._singleSignon.onSignIn(res.messageCode);
              }
            });
          }
        }
      }
    }
  }

  logInSamlO365(username: string): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      AccessMode: 'W',
      domainCode: localStorage.getItem('domainCode'),
    });
    const body = new URLSearchParams({
      logOnParam: username
    }).toString();
    this._httpClient.post(this.http._config.base_auth_url + '/api/saml/request/login', body, { headers }).subscribe((res: IHroneGenericResponse) => {
      if (res?.validationType == '0') {
        this._singleSignon.onSignIn(res.messageCode);
      } else {
        this._snackBar.Alert(res.message, res.validationType);
      }
    });
  }
}
