/*
 *  This file hrone-idle.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */


import { Injectable } from '@angular/core';
import { Observable, merge, fromEvent, Subject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HroneIdleService {
  private idle$!: Observable<Event>;
  private timer$!: Subscription;
  private timeOutMilliSeconds!: number;
  private idleSubscription!: Subscription;
  public expired$: Subject<boolean> = new Subject<boolean>();
  readonly REAUTH_BROADCAST_CHANNEL: BroadcastChannel = new BroadcastChannel('reauth');
  private hideIdlePopup$ = new Subject<boolean>();
  private showPopup = new Subject<boolean>();


   startWatching(timeOutSeconds: number): Observable<boolean> {

    this.idle$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'resize'),
      fromEvent(document, 'wheel'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchstart'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'touchend'),
      fromEvent(document, 'gesturestart'),
      fromEvent(document, 'gesturechange'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
      fromEvent(window, 'MSPointerMove'),
    );


    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.REAUTH_BROADCAST_CHANNEL.onmessage = (event) => {
      if (event.data === 'active') {
        this.resetTimer();
      }
      else if (event.data === 'inactive') {
        this.showPopup.next(true);
      }
      else if(event.data === 'hide') {
        this.hideIdlePopup$.next(false);
        this.resetTimer();
      }
    };
    this.idleSubscription = this.idle$.subscribe(() => {
      const show = JSON.parse(localStorage.getItem('showIdlePopup')!);
      if (show) {
        this.showPopup.next(true);
      }
      else{
        const anyStringWouldWork = "active";
        this.REAUTH_BROADCAST_CHANNEL.postMessage(anyStringWouldWork)
        this.resetTimer();
      }
    });
    this.REAUTH_BROADCAST_CHANNEL.postMessage('active');
    this.startTimer();

    return this.expired$.asObservable();
  }
   startTimer() {
    this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe(() => {
      this.expired$.next(true);
    });
  }

   resetTimer(timeOutSeconds?: number) {
    this.timer$.unsubscribe();
    if (timeOutSeconds) {
      this.timeOutMilliSeconds = timeOutSeconds * 1000;
    }
    this.startTimer();
  }
   stopTimer() {
    this.timer$.unsubscribe();
    this.idleSubscription.unsubscribe();
  }
  get hidePopup(): Observable<boolean> {
    return this.hideIdlePopup$.asObservable();
  }
  get showPopUp$(): Observable<boolean> {
    return this.showPopup.asObservable();
  }
}
