/*
 *  This file authenticate-user.component.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router'
import { mandatoryField } from 'src/app/global/validators/validators';
import { AuthService } from '../../services/auth-service';
import { ProgressButtonService } from '../../services/progress-button.service';
import { SharedService } from '../../services/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SingleSignonService } from 'src/app/authentication/single-signon.service';
import { ServerErrorHandler } from '../../services/error-handler/server-error-handler.service';
import { HttpService } from '../../services/http-service';
import { LoadScriptService } from '../../services/load-script.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { HroneIdleService } from '../../services/hrone-idle.service';
import { Subject } from 'rxjs';
import { LogOnSetting } from 'src/app/authentication/login/login.model';
import { LaddaModule } from 'angular2-ladda';
import { SharedModule } from 'primeng/api';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogModule } from 'primeng/dialog';
import { TMessageResponse } from '../../models/common.models';

declare const gapi: any;

@Component({
  selector: 'app-authenticate-user',
  templateUrl: './authenticate-user.component.html',
  styleUrls: ['./authenticate-user.component.scss'],
  standalone: true,
  imports: [DialogModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, TooltipModule, NgIf, SharedModule, LaddaModule]
})
export class AuthenticateUserComponent implements OnInit, OnDestroy {
  isNormalLoginScreenTimeOut = false;
  loginForm!: UntypedFormGroup;
  counter = 0;
  buttons = {
    login: { progress: false, buttonName: 'LOG IN' }
  };
  showPassword = false;
  isSSOLoginScreenTimeOut = false;
  takeUntil$ = new Subject();
  constructor(
    private _fb: UntypedFormBuilder,
    public _authService: AuthService,
    private _progressButton: ProgressButtonService,
    public _shared: SharedService,
    public _router: Router,
    public _singleSignon: SingleSignonService,
    private _loadScript: LoadScriptService,
    private _zone: NgZone,
    private _http: HttpService,
    private _httpClient: HttpClient,
    private _serverError: ServerErrorHandler,
    private _hroneIdle: HroneIdleService,
  ) { }
  ngOnDestroy(): void {
    this.takeUntil$.complete();
    this.takeUntil$.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();
    this._hroneIdle.hidePopup.pipe(takeUntil(this.takeUntil$)).subscribe({
      next: (hide: boolean) => {
        this.isNormalLoginScreenTimeOut = hide;
      }
    })
  }

  createForm(): void {
    this.loginForm = this._fb.group({
      password: ['', [mandatoryField]]
    });
  }

  showDialog(): void {
    this.isNormalLoginScreenTimeOut = false;
    this.isSSOLoginScreenTimeOut = false;
    const path = this._router.url ? this._router.url.toLowerCase() : '';
    if (path == '/login') {
      return;
    }
    const loginType = localStorage.getItem('loginType');
    if (loginType == 'normal') {
      this.isNormalLoginScreenTimeOut = true;
    } else {
      this.isSSOLoginScreenTimeOut = true;
    }
    this.loginForm.reset();
  }

  onSubmit(): void {
    this._progressButton.clickedNew(this.buttons.login);
    const url = '/oauth2/token';
    const dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._authService._config.domainCode;
    const body = 'username=' + encodeURIComponent(this._shared.logOnUserDetails.mobileNo) +
      '&pas' + 'swor' + 'd=' + encodeURIComponent(this.loginForm.value.password) +
      '&grant_type=' + 'password' +
      '&isScreenLock=' + true +
      '&loginType=' + 1 +
      '&companyDomainCode=' + dc + '' + '&validSource=Y';

    this._authService.LoginUnAuthorized(url, body).subscribe((res:TMessageResponse) => {
      if (res.validationType === '0') {
        this.isNormalLoginScreenTimeOut = false;
        this._hroneIdle.REAUTH_BROADCAST_CHANNEL.postMessage('hide');
        this._hroneIdle.resetTimer();
        localStorage.setItem('showIdlePopup', JSON.stringify(false));
      }      
      this._progressButton.finishedNew(this.buttons.login, 'LOG IN', '3');
    }, () => {
      this._progressButton.finishedNew(this.buttons.login, 'LOG IN', '3');
    }
    );
  }

  OnUnlock(): void {
    const loginType = localStorage.getItem('loginType');
    const logOnTypeDetails = JSON.parse(localStorage.getItem('logOnTypeDetails'));
    const username = localStorage.getItem('logOnUserName') ? localStorage.getItem('logOnUserName') : this._shared.logOnUserDetails.officialEmail;
    if (loginType == 'ad') {
      this._singleSignon.onSignIn();
    } else if (loginType == 'saml') {
      if (logOnTypeDetails?.logOnSetting?.isSpInitiate) {
        this._authService.logInSamlO365(username);
      } else {
        this._singleSignon.onSignIn();
      }
    } else if (loginType == 'google') {
      this.loadGoogleLoginScript(this._shared.logOnTypeDetails?.logOnSetting, false);
    } else if (loginType == 'msal') {
      this._singleSignon.onMsalLogin();
    } else {
      this._shared._snackBar.Alert('Please contact administrator', '3');
    }
  }

  loadGoogleLoginScript(client: LogOnSetting, isLoadScipt: boolean): void {
    this._loadScript.load('googlePlatform').then(() => {
      this.initializeGoogleLogin(client, isLoadScipt);
    }).catch();
  }

  initializeGoogleLogin(client: LogOnSetting, isLoadScipt: boolean) {
    gapi.load('auth2', () => {
      this._zone.run(() => {
        const auth2 = gapi.auth2.init({
          client_id: client.clientId,
          cookiepolicy: 'single_host_origin',
          scope: 'https://www.googleapis.com/auth/userinfo.email',
          redirect_uri: client && client.redirectPath
        });
        this.renderButton();
        if (isLoadScipt) {
          return;
        }
        this.prepareGoogleLoginButton(auth2);
      });
    });
  }

  renderButton() {
    gapi.signin2.render('googleBtn',
      {
        'scope': 'profile email',
        'width': 188,
        'height': 40,
        'longtitle': true,
        'theme': 'dark',
        'onsuccess': () => { },
        'onfailure': () => { }
      });
  }
  //auth2 is of type any since we don't have type d for this lib.
  prepareGoogleLoginButton(auth2: any): void {
    const gLoginBtn = document.getElementById('glogin');
    auth2.attachClickHandler(gLoginBtn, {},
      (googleUser: any) => {
        this._zone.run(() => {
          this.onSingleSignonLogin(4, googleUser.getAuthResponse().id_token);
        });
      }, () => {
        location.reload();
      });
    setTimeout(() => {
      gLoginBtn?.click();
    }, 300);
  }

  onSingleSignonLogin(type: number, token: string): void {
    if (localStorage.getItem('domainCode')) {
      this._http._config.domainCode = localStorage.getItem('domainCode') ?? "";
    }
    const url = this._http._config.base_auth_url + '/api/sso2hrone/token';
    const adSetting = JSON.parse(localStorage.getItem('adSetting') ?? JSON.stringify({ userPrincipalName: "" }));
    const headers = new HttpHeaders({
      domainCode: this._http._config.domainCode,
      ssoToken: token,
      logOnType: String(type),
      logOnParam: localStorage.getItem('logOnUserName') ?? '',
      userPrincipalName: adSetting.userPrincipalName,
      AccessMode: 'W'
    });
    this._httpClient
      .post(url, {}, { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')))
      .subscribe(res => {
        localStorage.setItem('tokenRedirectionDetails', JSON.stringify(res));
        localStorage.setItem('showIdlePopup', JSON.stringify(false));
        const url = window.location.origin + '/hroneAuth';
        window.open(url, '_self');
      }, () => {
        this._router.navigate(["login"])
      }
      );
  }

  logOut(): void {
    const logOnUserDetails = JSON.parse(localStorage.getItem('logOnUserDetails'));
    const samlSessionIndex = localStorage.getItem('samlSessionIndex');
    this._authService.logout();
    this.isNormalLoginScreenTimeOut = false;
    this.isSSOLoginScreenTimeOut = false;
    this._hroneIdle.REAUTH_BROADCAST_CHANNEL.postMessage('hide');
    this._hroneIdle.resetTimer();
    setTimeout(() => {
      const logOnTypeDetails = JSON.parse(localStorage.getItem('logOnTypeDetails') || '');
      if (logOnTypeDetails) {
        this._authService.logOutFromSingleSignOn(logOnTypeDetails, logOnUserDetails, samlSessionIndex);
      } else {
        const val = encodeURIComponent(this._shared.logOnUserDetails?.officialEmail);
        this._http.GetUnAuthorize('/api/Default/LogOnType?logOnParam=' + val).subscribe(res => this._authService.logOutFromSingleSignOn(res, logOnUserDetails, samlSessionIndex));
      }
      localStorage.removeItem('logOnTypeDetails');
    }, 200);
  }
}
