
/* 
 *  This file logon-user-resolver.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { Injectable } from '@angular/core';

import { HttpService } from './http-service';
import { SharedService } from './shared.service';
import { AuthService } from './auth-service';
import { UserRightsService } from './user-rights.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class LogonUserResolverService {
    constructor(
        public _httpService: HttpService,
        public _shared: SharedService,
        public _auth: AuthService,
        public _userRights: UserRightsService,
        public _config: ConfigurationService
    ) { }

    async resolve() {
        const logOnUserDetails = JSON.parse(localStorage.getItem('logOnUserDetails'));
        const rawMenus = JSON.parse(localStorage.getItem('rawMenus'));
        const rawMenusWithActions = JSON.parse(localStorage.getItem('rawMenusWithActions'));
        const whiteLabels = JSON.parse(localStorage.getItem('whiteLabels'));

        if (logOnUserDetails && rawMenus && rawMenusWithActions) {
            this._shared.logOnUserDetails = logOnUserDetails;
            if (this._shared.logOnUserDetails && this._shared.logOnUserDetails.identifier) {
                this._shared.logOnUserIdentifiers = this._shared.logOnUserDetails.identifier;
            }
            this._shared.rawMenusWithActions = rawMenusWithActions;
            this._userRights.rawMenusWithActions = rawMenusWithActions;
            this._shared.rawMenus = rawMenus;
            this._shared.refreshRecentSearchedProfileData();
            this._shared.getTokenInfo();
        } else {
            this._auth.logout();
            return;
        }
        const labels = whiteLabels ? whiteLabels : [];
        labels.forEach(ele => {
            const prop = (ele.originalDisplayName)?.toLowerCase();
            this._shared.whiteLabels[prop] = ele.displayName;
        });
    }
}